import _typeof from "./typeof.js";
import _Symbol$toPrimitive from "core-js/library/fn/symbol/to-primitive.js";
function toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[_Symbol$toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
export { toPrimitive as default };